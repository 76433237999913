import React, { useEffect } from "react";
import { Row, Col } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";
import { FooterSection, Para, Extra } from "./styles";
import styled from "styled-components";

const ResponsiveFooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; // Allow items to wrap on small screens
  width: 100%; // Ensure it takes full width
  a {
    margin: 10px; // Add margin to each icon
  }
`;

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: { t: TFunction }) => {
  const SocialLink = ({ href, src }: SocialLinkProps) => (
    <a href={href} target="_blank" rel="noopener noreferrer" key={src} aria-label={src}>
      <SvgIcon src={src} width="25px" height="25px" />
    </a>
  );

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://miramove.ck.page/792e73fdd9/index.js";
    script.async = true;
    document.body.appendChild(script);

    const style = document.createElement("style");
    style.innerHTML = `
      .formkit-powered-by-convertkit {
        display: none !important;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.body.removeChild(script);
      document.head.removeChild(style);
    };
  }, []);

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="center">
            <Col lg={12} md={12} sm={24} xs={24} style={{ padding: "20px 0" }}>
              <Para style={{ paddingBottom: "11px", textAlign: "center" }}>{t("Join the Newsletter")}</Para>
              <div id="ck_form_container" style={{ textAlign: "center" }}>
                <script
                  async
                  data-uid="792e73fdd9"
                  src="https://miramove.ck.page/792e73fdd9/index.js"
                ></script>
              </div>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row justify="center" align="middle" style={{ paddingTop: "3rem", textAlign: "center" }}>
            <ResponsiveFooterContainer>
              <SocialLink href="https://www.instagram.com/miramove.app/?igshid=MmIzYWVlNDQ5Yg%3D%3D" src="instagram.svg" />
              <SocialLink href="https://www.youtube.com/channel/UCb7b_xZ3an4bdtaX5wero6Q" src="youtube.svg" />
              <SocialLink href="https://discord.com/invite/Y7pVc5J9zR" src="discord.svg" />
            </ResponsiveFooterContainer>
          </Row>
          <Row justify="center" style={{ marginTop: "1rem", textAlign: "center" }}>
            <Para>MINDLINK LTD © ALL RIGHTS RESERVED.</Para>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
